.feather {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    stroke-width: 1.5px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    stroke: currentColor;

    // MD
    width:1.75rem;
    height:1.75rem;
}

.featherSm {
    width:1rem;
    height:1rem;
}

.featherLg {
    width:2.5rem;height:2.5rem;
}

.btn > .feather, .nav-link > .feather {
    margin-top:2px;
    &, & + span {
        display:inline-block;
        vertical-align: middle;
    }
    & + span {margin-left:.25rem;}
}

.sw-2 { stroke-width:2px; }